/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    hdd: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4.5 11a.5.5 0 100-1 .5.5 0 000 1M3 10.5a.5.5 0 11-1 0 .5.5 0 011 0"/><path pid="1" d="M16 11a2 2 0 01-2 2H2a2 2 0 01-2-2V9.51c0-.418.105-.83.305-1.197l2.472-4.531A1.5 1.5 0 014.094 3h7.812a1.5 1.5 0 011.317.782l2.472 4.53c.2.368.305.78.305 1.198zM3.655 4.26L1.592 8.043Q1.79 8 2 8h12q.21 0 .408.042L12.345 4.26a.5.5 0 00-.439-.26H4.094a.5.5 0 00-.44.26zM1 10v1a1 1 0 001 1h12a1 1 0 001-1v-1a1 1 0 00-1-1H2a1 1 0 00-1 1"/>',
    },
});
